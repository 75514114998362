<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="dark" :transparent="true" v-click-outside="closeNavbar">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ $t(`sidebar.${routeName}`) }}</a>
    </div>
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">


    </ul>
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <div class="search-bar input-group" @click="searchModalVisible = true">
        
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>

        <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
          <i class="tim-icons icon-zoom-split"></i>
        </button>

      </div>
      <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" :show-close="true">
        <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup"
          placeholder="SEARCH" />
      </modal> -->

      <!-- <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item">
        <template slot="title">
          <div class="settings-icon d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-settings-gear-63"></i>
        </template>
      </base-dropdown> -->
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="switch"  v-click-outside="closeDropDown" style="height: auto !important; padding:0; margin-right: 10px">
      <template slot="title">
          <a>
            <i class="tim-icons icon-settings-gear-63" style="font-size: 1.4rem;"> </i>
            <!-- <i v-else class="tim-icons icon-settings-gear-63" style="font-size: 1.4rem; color:rgb(29, 37, 59)"> </i> -->
          </a>
          
        </template>
        <ul class="dropdown-menu" :class="{ show: isOpen }">

          <li class="header-title">{{ $t('navbar.Settings') }}</li>
          <li class="adjustments-line">

            <div class="togglebutton switch-change-color mt-3">
              <span class="label-switch">{{ $t('FixedSettings.LIGHTMODE') }}</span>
              <base-switch v-model="darkMode_model" @input="toggleMode"></base-switch>
              <span class="label-switch label-right">{{ $t('FixedSettings.DARKMODE') }}</span>
            </div>
            <div class="togglebutton switch-change-color mt-3">
              <span class="label-switch label-right">EN</span>
              <base-switch v-model="selectedLanguage" @input="ChangeLanguage();calldrawChart();"></base-switch>
              <span class="label-switch">DE</span>
            </div>
          </li>

        </ul>
      </base-dropdown>




      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item" style="height: auto !important;" >
        <template slot="title">
          <div v-if="snUpdateList.length > 0" class="notification d-lg-block d-xl-block"></div>
          <a :class="{ 'icon-disabled': snUpdateList.length === 0 }" @click.prevent="snUpdateList.length === 0"></a>
          <i class="tim-icons icon-bell-55" :class="{ 'icon-disabled': snUpdateList.length === 0 }"></i>
        </template>

        <li class="nav-link">
          <router-link class="nav-item" tag="li" to="/update">
            <div v-if="snUpdateList.length > 0">
        <li v-for="sn in snUpdateList" :key="sn">
          <a href="#" class="nav-item dropdown-item" style="color: aliceblue !important;">{{ $t('navbar.notification1') }} {{ sn }}</a>
        </li>
        </div>
        <div v-else>
          <a href="#" class="nav-item dropdown-item" style="color: aliceblue !important; height: auto;">{{ $t('navbar.NoUpdate') }}</a>
        </div>
        </router-link>
        </li>

      </base-dropdown>
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item"
        menu-classes="dropdown-navbar" style="height: auto;">
        <template slot="title">
          <div class="photo"><img src="img/saxicon.png" /></div>
          <!-- <b class="caret d-none d-lg-block d-xl-block" style="top: 2.5rem;"></b> -->

        </template>

        <router-link class="nav-item" tag="li" to="/Settings">
          <a class="nav-link dropdown-item" style="color: aliceblue !important;">
            <i class="tim-icons icon-settings"></i>{{ $t('navbar.Settings') }}
          </a>
        </router-link>
        <div class="dropdown-divider"></div>
        <router-link class="nav-item" tag="li" to="/logout">
          <a class="nav-link dropdown-item" style="color: aliceblue !important;">
            <i class="tim-icons icon-single-02"></i> {{ $t('navbar.LogOut') }}
          </a>
        </router-link>

      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import { BaseSwitch } from 'src/components';
import { EventBus } from 'src/EventBus.js';

export default {
  name: 'sidebar-share',
  components: {
    SidebarToggleButton,
    BaseNav,
    // Modal,
    BaseSwitch,

  },
  props: {
    backgroundColor: String
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    if (localStorage.getItem("languageSelected") === "true") {
      this.selectedLanguage = true;
    } else {
      this.selectedLanguage = false;
    }
    this.ChangeLanguage();
  },
  computed: {
    snUpdateList() {
      return this.$store.getters.getSnUpdateList;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      // selectedLanguage: true,
      sidebarMini: true,
      darkMode_model: JSON.parse(localStorage.getItem('darkMode')),
      isOpen: false,
      sidebarColors: [
        { color: 'primary', active: false, value: 'primary' },
        { color: 'vue', active: true, value: 'vue' },


      ],
      triggerNotify: false,
    };
  },
  watch: {
    'snUpdateList.length'(newLength) {
    if (newLength >= 1) {
      this.notifyme();
    }
  }
},
  methods: {
    updateAvailable(e) {
      const confirmationResult = confirm("Eine neue Version der App ist verfügbar. Jetzt aktualisieren?");
      if (confirmationResult) {
        const worker = e.detail.installing || e.detail.waiting;
        worker.postMessage({ action: 'skipWaiting' });
      }
    },
    calldrawChart() {
      EventBus.$emit('calldrawChart');
    },
    notifyme(){
      let UpdateList = this.$store.getters.getSnUpdateList;
      if (UpdateList.length > 0) {
      // console.log("notify", UpdateList.length );
        this.$notify({
          type: 'primary',
          timeout: 2000,
          message: this.$t('navbar.notification1') + UpdateList[UpdateList.length-1],
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    // closeDropDown() {
    //   this.activeNotifications = false;
    // },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleDarkMode() {
      const darkMode = this.$store.getters.getDarkMode;
      const newDarkMode = !darkMode;
      this.$store.commit('setDarkMode', newDarkMode);
      localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    closeNavbar() {
      this.showMenu = false;
    },
    toggleList(list, itemToActivate) {
      list.forEach(listItem => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    },
    changeSidebarBackground(item) {
      this.$emit('update:backgroundColor', item.value);
      this.toggleList(this.sidebarColors, item);
    },
    ChangeLanguage() {
      if (this.selectedLanguage) {
        this.$i18n.locale = "de";
        localStorage.setItem("languageSelected", true);

      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("languageSelected", false);
      }
    },
    toggleMode(type) {
      this.toggleDarkMode();
      let docClasses = document.body.classList;
      if (type) {

        docClasses.remove('white-content');
      } else {

        docClasses.add('white-content');
      }
    },
    checkMode() {
      const persistedDarkMode = JSON.parse(localStorage.getItem('darkMode'));
      const darkMode = persistedDarkMode !== null ? persistedDarkMode : this.$store.getters.getDarkMode;
      this.$store.commit('setDarkMode', darkMode);
      // console.log("darkMode", darkMode);
      let docClasses = document.body.classList;
      if (darkMode) {
        docClasses.remove('white-content');
      } else {
        docClasses.add('white-content');
      }
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    }
  },
  mounted() {
    this.checkMode()
  }
};
</script>
<style scoped lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}

.top-navbar {
  top: 0px;
}

.icon-disabled {
  opacity: 0.5 !important;
}
.switch {
  cursor: pointer;
  position: relative !important;
  display: inline-block;
  height: 34px;
  margin-top: .5rem;
  margin-right: 10px !important;
}
.dropdown {
  height: 10px;
}

.dropdown-menu{
  position: relative !important;
  color: $active-gray;
  width: 17rem;
  border-radius: 0.1875rem;
  background: linear-gradient($black);
  text-align: center;
  margin-top: 0;
}
.dropdown-menu:before{
  right: 10px;
  margin-left: auto;
  left: auto;
  border: 0;
}
.header-title{
  margin-top: 1rem !important;
  color: $white;
}
.label-switch{
  padding: 5px;
  color: $white;
}
@media screen and (min-width: 1024px){
  .photo{
    height: 100%;
    margin-top: .3rem;
  }
  .icon-settings-gear-63{
    margin-top: 0;

    &:hover,
    &:focus{
      color: theme-color("primary") !important;
      transition: color 0.3s ease;
    } 
  }
}
@media screen and (min-width: 120px) and (max-width: 1023px) {
  .switch{
    color: $white !important;
    i{
      opacity: 1 !important;
      margin-left: .8rem !important;
    }
    p{
      display: inline-block !important;
      text-transform: uppercase !important;
      margin-left: 7px !important;
    }
  }
  .header-title{
    top: 10rem !important;
    padding-top: 1rem !important;
  }
  .photo{
    height:min-content;
    margin-top: 1rem !important;
  }
  .icon-settings-gear-63{
    color:aliceblue !important;
  }
  .notification.d-xl-block{
    left: 2.2rem !important;
  }
}
</style>
